<template>
    <div class="service-agreement-wrapper">
        <h2>服务协议</h2>
        <div class="service-agreement-content">
            你在使用北京代码澎湃科技有限公司（以下简称“超赞”）及关联公司提供的各项服务之前，
            <u><strong>请你务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如你不同意本服务协议及/或随时对其的修改，你应立即停止注册；你一旦使用超赞提供的服务，即视为你已了解并完全同意本服务协议各项内容，包括超赞对服务协议所做的修改，并成为我们的用户。</strong></u>
        </div>
        <div class="service-agreement-title">
            一、帐号注册
        </div>
        <div class="service-agreement-content">
            1、当你注册成功帐号并且与某个超赞提供技术支持的店铺发生行为关联时（包括不限于获得店铺优惠券、登录商家店铺后台、产生购买行为），你授权该商家获得你的手机帐号以方便下单购买。
        </div>
        <div class="service-agreement-content">
            2、当你选择通过第三方授权登录程序进入超赞，你需授权超赞收集你的帐号昵称及头像等标识化的个人信息，授权完成后，你即成为超赞的“用户”。
            <div>当你按照注册页面提示填写信息并注册成功后，你即成为超赞的“用户”；超赞将给予你一个用户帐号，密码由你自行设置。该用户帐号和密码由你自行负责保管。</div>
            <div>你应当对你在超赞进行的活动和事件负法律责任。</div>
        </div>
        <div class="service-agreement-content">
            3、<u><strong>本协议服务条款和公告可由超赞定时更新，并按照法定流程进行公示。你在使用相关服务时,应关注并遵守其所适用的相关条款。</strong></u>
        </div>
        <div class="service-agreement-content">
            4、<u><strong>使用同一身份认证信息、同一手机号或经超赞排查认定多个超赞账户的实际控制人为同一人的，均视为同一用户。</strong></u>
        </div>
        <div class="service-agreement-content">
            5、你确认，在你开始使用本服务前，<u><strong>你应当具备中华人民共和国法律规定的与你行为相适应的民事行为能力。若你不具备前述与你行为相适应的民事行为能力，则你需要在你监护人的陪同下完成注册，并且你与你的监护人应依照法律规定承担因此而导致的一切后果。</strong></u>
        </div>
        <div class="service-agreement-title">
            二、注册信息和隐私保护
        </div>
        <div class="service-agreement-content">
            1、你帐号的所有权归超赞，使用权归你。你按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得注册帐号并成为用户。你应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。<u><strong>如果因注册信息不真实或更新不及时而引发的相关问题，由你自行承担相应的责任。</strong></u>
        </div>
        <div class="service-agreement-content">
            2、你应当通过真实身份信息认证注册帐号，且你提交的帐号名称、头像、 简介等注册信息中不得出现违法和不良信息，经公司审核，如存在上述情况，超赞将不予注册；<u><strong>同时，在注册后，如发现你以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，超赞有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。</strong></u>
        </div>
        <div class="service-agreement-content">
            3、你帐号包括帐户名称和密码，账户名称作为你有效的身份凭证之一。你可使用手机号和密码登录。
        </div>
        <div class="service-agreement-content">
            4、你不应将其帐号、密码转让、出售或出借予他人使用，若你授权他人使用帐户，应对被授权人在该帐户下发生所有行为负全部责任。<u><strong>由于你其他账户使用信息，仅当依法律法规、司法裁定或经超赞同意，并符合超赞规定的用户帐号转让流程的情况下，方可进行帐号的转让。</strong></u>
        </div>
        <div class="service-agreement-content">
            5、因你个人原因导致的帐号信息遗失，如需找回帐号信息，请按照帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求，无法通过安全验证，<u><strong>超赞有权拒绝提供帐号找回服务；若帐号的唯一凭证不再有效，超赞有权拒绝支持帐号找回。例如手机号二次出售，超赞可拒绝支持帮助找回原手机号绑定的帐号。</strong></u>
        </div>
        <div class="service-agreement-content">
            6、<u><strong>在需要终止使用帐号服务时，符合以下条件的，你可以申请注销你的帐号：</strong></u>
            <div>（1）你仅能申请注销你本人的帐号，并依照超赞的流程进行注销；</div>
            <div>（2）你仍应对你在注销帐号前且使用的行为承担相应责任，同时超赞仍可保存你注销前的相关信息；</div>
            <div>（3）<u><strong>注销成功后，帐号信息、个人身份信息、交易记录、会员权益等将无法恢复或提供。</strong></u></div>
        </div>
        <div class="service-agreement-content">
            7、当你使用超赞产品进行支付、登录等操作的时候，服务器会自动接收、验证、并记录一些必要的信息，如手机号码、IP地址、服务访问异常信息、以及部分设备信息等，以保障你在使用服务时账户登录和支付过程的安全，进而保护你的上网安全。<u><strong>你使用超赞的服务，即表示你同意超赞可以按照《隐私政策》处理你的个人信息。超赞可能会与合作伙伴共同向你提供你所要求的服务或者共同向你展示你可能感兴趣的内容。在信息为该项产品/服务所必须的情况下，你同意超赞可与其分享必要的信息。并且，超赞会要求其确保数据安全并且禁止用于任何其他用途。除此之外，超赞不会向任何无关第三方提供或分享信息。</strong></u>
        </div>
        <div class="service-agreement-content">
            8、你知悉并授权，超赞仅在必需的情况下使用或与关联公司同步你的信息，以为你提供更好的服务。
        </div>
        <div class="service-agreement-content">
            9、<u><strong>为更好地向你提供服务，你同意超赞通过短信、app通知等形式向你发送相关商业性服务信息。</strong></u>
        </div>
        <div class="service-agreement-title">
            三、使用规则
        </div>
        <div class="service-agreement-content">
            1、你确认，当你注册成功并且与某个店铺发生行为关联时，如你获得商家的优惠券、登录商家店铺后台、产生购买行为等，你确认并授权超赞提供技术支持的店铺获得你的手机号，以方便你的购买。
        </div>
        <div class="service-agreement-content">
            2、你在使用超赞的服务时，必须遵守中华人民共和国相关法律法规的规定，你应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:
            <div><u><strong>（1）你设置的会员名或提供的信息不得含有下列内容之一的信息：</strong></u></div>
            <div class="service-agreement-indent">① 反对宪法所确定的基本原则的；</div>
        　　	<div class="service-agreement-indent">② 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
        　　	<div class="service-agreement-indent">③ 损害国家荣誉和利益的；</div>
        　　	<div class="service-agreement-indent">④ 煽动民族仇恨、民族歧视、破坏民族团结的；</div>
        　　	<div class="service-agreement-indent">⑤ 破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
        　　	<div class="service-agreement-indent">⑥ 散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
        　　	<div class="service-agreement-indent">⑦ 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
        　　	<div class="service-agreement-indent">⑧ 侮辱或者诽谤他人，侵害他人合法权利的；</div>
        　　	<div class="service-agreement-indent">⑨ 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</div>
        　　	<div class="service-agreement-indent">⑩ 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</div>
            <div>（2）不得为任何非法目的而使用超赞服务系统；</div>
            <div>（3）不利用超赞的服务从事以下活动：</div>
            <div class="service-agreement-indent">① 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</div>
        　　	<div class="service-agreement-indent">② 未经允许，对计算机信息网络功能进行删除、修改或者增加的；</div>
        　　	<div class="service-agreement-indent">③ 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</div>
        　　	<div class="service-agreement-indent">④ 故意制作、传播计算机病毒等破坏性程序的；</div>
        　　	<div class="service-agreement-indent">⑤ 其他危害计算机信息网络安全的行为。</div>
        </div>
        <div class="service-agreement-content">
            3、你违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，你同意赔偿超赞与合作公司、关联公司，并使之免受损害。<u><strong>对此，超赞有权视你的行为性质，采取包括但不限于删除你发布信息内容、暂停使用许可、终止服务、限制使用、回收帐号、追究法律责任等措施。对恶意注册帐号或利用帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，超赞有权回收其帐号。</strong></u>同时，超赞会视司法部门的要求，协助调查。
        </div>
        <div class="service-agreement-content">4、你需要对自己在服务使用过程中的行为负法律责任，即使你已经终止使用该服务。你承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在超赞首先承担了因你的行为导致的行政处罚或侵权损害赔偿责任后，你应给予超赞等额的赔偿。</div>
        <div class="service-agreement-content">5、超赞有权根据认定程序及标准判断你账户的使用可能危及你的账户安全及/或超赞平台信息安全的，超赞可拒绝提供相应服务或终止本协议。</div>
        <div class="service-agreement-title">
            四、服务内容
        </div>
        <div class="service-agreement-content">
            1、<u><strong>你在交易过程中与其他用户发生争议的，你或其他用户中任何一方可以自行协商解决，也可以通过超赞介入处理，若你觉得以上的方式均无法达到你要的结果，你可以寻求司法机关的协助。</strong></u>
        </div>
        <div class="service-agreement-content">
            2、你选择超赞介入的方式处理相关问题，那么你需要遵守和执行超赞的处理结果。<u><strong>若你对超赞的处理结果不满意，你可以寻求其他途径解决纠纷例如起诉、仲裁等方式，但在此之前你需先履行该结果。</strong></u>
        </div>
        <div class="service-agreement-content">
            3、<u><strong>超赞目前为你提供免费提供服务，但超赞保留因业务调整或者其他法律法规要求向你收取费用的权利。</strong></u>
        </div>
        <div class="service-agreement-content">
            4、超赞网络服务的具体内容由超赞根据实际情况提供。
        </div>
        <div class="service-agreement-content">
            5、<u><strong>鉴于网络服务的特殊性，你同意超赞有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。超赞不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</strong></u>
        </div>
        <div class="service-agreement-content">
            6、<u><strong>你需遵守超赞7天无理由退换货的相关规则。</strong></u>
        </div>
        <div class="service-agreement-content">
            7、小程序中的结算服务，均有你自行向支付平台（如微信支付等）申请支付账号，并将支付账号在超赞平台进行必要的设置。买家付款后，款项会直接达到你的支付账号，超赞不提供担保交易服务。买卖双方所产生的纠纷由双方协商解决，超赞不参争议的调解和解决。
        </div>
        <div class="service-agreement-content">
            8、终止服务或访问限制
            <div>（1）<u><strong>您或超赞可随时有权根据实际情况终止超赞网服务，超赞不需对任何个人或第三方负责而随时中断服务。</strong></u></div>
            <div>（2）在您未向超赞支付任何服务费用的情况下，超赞可自行全权决定以任何理由 (包括但不限于超赞认为您已违反本协议的字面意义和精神，或您以不符合本协议的字面意义和精神的方式行事，或您在超过30天的时间内未以您的帐号及密码登录超赞网产品) 终止您对超赞网平台服务的使用，及可自行全权决定以任何理由 (包括但不限于超赞认为您已违反本协议的字面意义和精神，或您以不符合本条款的字面意义和精神的方式行事，或您在超过60天的时间内未以您的帐号及密码登录超赞网产品) 终止您的超赞网服务密码、帐户 (或其任何部份) 或并删除和丢弃您在使用超赞网平台服务中提交的资料。</div>
        </div>
        <div class="service-agreement-title">
            五、个人数据保护与授权
        </div>
        <div class="service-agreement-content">
            1、为了保护你的财产及信息安全，预防诈骗或者其他网络犯罪行为，超赞有权收集你的个人信息，包括但不限于个人会员信息、交易信息来判断你个人的交易风险，对你身份进行验证等。
        </div>
        <div class="service-agreement-content">
            2、<u><strong>根据相关法律法规规定，以下情形中收集你的个人信息无需征得你的授权同意：</strong></u>
            <div class="service-agreement-indent">①、涉及公共安全，与重大利益相关的；</div>
            <div class="service-agreement-indent">②、与政府部门包括司法机关等相关的；</div>
            <div class="service-agreement-indent">③、出于保护你的个人财产或者利益的目的，但无法事先征求你同意的行为；</div>
            <div class="service-agreement-indent">④、你以自行对外公开的，包括但不限于通过报纸、新闻等方式公开；</div>
            <div class="service-agreement-indent">⑤、根据你的要求签订合同所必需的；</div>
            <div class="service-agreement-indent">⑥、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</div>
            <div class="service-agreement-indent">⑦、国家法律法规政策认可或支持的相关行为；</div>
        </div>
        <div class="service-agreement-content">
            3、我们已采取符合业界标准、合理可行的安全防护措施保护你提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
        </div>
        <div class="service-agreement-title">
            六、免责声明
        </div>
        <div class="service-agreement-content">
            <u><strong>因以下情况造成网络服务在合理时间内的中断，超赞无需为此承担任何责任；</strong></u>
        </div>
        <div class="service-agreement-content">
            <div>（1）<u><strong>超赞需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，超赞保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</strong></u></div>
            <div>（2）<u><strong>因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</strong></u></div>
            <div>（3）<u><strong>你的电脑软硬件和通信线路、供电线路出现故障的；</strong></u></div>
            <div>（4）<u><strong>因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。</strong></u></div>
            <div> <u><strong>尽管有前款约定，超赞将采取合理行动积极促使服务恢复正常。</strong></u></div>
        </div>
        <div class="service-agreement-title">
            七、其他规定
        </div>
        <div class="service-agreement-content">
            1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。
        </div>
        <div class="service-agreement-content">
            2、 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。
        </div>
        <div class="service-agreement-content">
            3、本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。
        </div>
        <div class="service-agreement-content">
            4、超赞未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
        </div>
        <div class="service-agreement-content">
            5、<u><strong>本协议最终解释权及修订权在法律范围内归超赞所有。</strong></u>
        </div>
        <div class="service-agreement-content">
            6.<u><strong>【管辖法院】你因使用超赞服务产生的争议，由超赞与你协商解决。协商不成时，任何一方均可向北京市海淀区人民法院提起诉讼。</strong></u>
        </div>
        <div class="service-agreement-title">
            八、其他补充协议
        </div>
        <div class="service-agreement-content">
            <strong class="color" @click="goPrivacy">超赞隐私协议</strong>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ServiceAgreement",
        methods:{
            goPrivacy(){
                this.$router.replace("/agreement/PrivacyAgreement");
            },
        },
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";
    .color{
        color: @color-primary;
        cursor: pointer;
        font-size: 16px;
    }
</style>
